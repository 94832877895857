import styled from '@emotion/styled';

const ElementMeasurerContent = styled.span<{ initialRender: boolean }>(
  ({ initialRender }) => ({
    display: 'inline-block',
    ...(initialRender ? { width: 0 } : {}),
  }),
);

export default ElementMeasurerContent;
