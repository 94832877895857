import styled from '@emotion/styled';

const Title = styled('div')(({ theme }) => ({
  color: theme.colors.gray.medium,
  display: 'inline-block',
  fontSize: theme.fonts.size['13'],
  letterSpacing: '0.2rem',
  marginRight: '10rem',
  width: 'calc(50% - 8.5rem)',
}));

export default Title;
