import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const InputContainer = styled('span')(({ theme }) => ({
  backgroundColor: theme.colors.white.primary,
  border: `0.1rem solid ${theme.colors.gray.light}`,
  borderRadius: '0.5rem',
  display: 'inline-block',
  fontSize: '2.2rem',
  fontWeight: 'bold',
  height: '4.5rem',
  outline: 'none',
  overflow: 'hidden',
  padding: '0.7rem 1rem',
  position: 'relative',
  verticalAlign: 'bottom',
  whiteSpace: 'nowrap',
  width: 'calc(50% - 1.5rem)',

  'input:focus': {
    border: 'none',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    height: '4rem',
    marginRight: 0,
    paddingBottom: 0,
    paddingTop: '0.4rem',
    width: '100%',
  },
}));

export default InputContainer;
