import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  adjustSpacing: boolean;
};

const AlbumSubtitle = styled('span')<Props>(({ adjustSpacing, theme }) => ({
  ...theme.mixins.ellipsis,
  display: 'block',
  fontSize: theme.fonts.size['13'],
  marginTop: adjustSpacing ? '-0.5rem' : '0.12rem',
  maxWidth: 'calc(100% - 1rem)',
  lineHeight: theme.fonts.lineHeight['16'],
  a: { color: theme.colors.gray['400'] },
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'none',
  },
}));

export default AlbumSubtitle;
