import styled from '@emotion/styled';

type Props = {
  isEditing?: boolean;
};

export const EditConfirm = styled('div')<Props>(
  ({ isEditing = false, theme }) => ({
    backgroundColor: theme.colors.white.primary,
    bottom: 0,
    boxShadow: '0px 0px 14px -1px rgba(0, 0, 0, 0.5)',
    height: '8rem',
    left: 0,
    maxHeight: isEditing ? '8rem' : '0',
    overflowY: 'hidden',
    position: 'fixed',
    right: 0,
    transition: 'max-height 0.8s ease',
    width: '100%',
    zIndex: 120,
  }),
);

export default EditConfirm;
