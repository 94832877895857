import Songs from './Songs';
import {
  addTrackToPlaylistSelector,
  editPlaylistSelector,
  playPlaylistOnDemandSelector,
  saveTrackPlaylistSelector,
  shuffleCuratedSelector,
  shuffleSelector,
} from 'state/Entitlements/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getCountryCode } from 'state/Config/selectors';
import {
  getCurrentIsCurated,
  getCurrentIsDeletable,
  getCurrentIsPremiumPlaylist,
  getCurrentIsRenameable,
  getCurrentIsShareable,
  getCurrentIsShuffled,
  getCurrentIsWritable,
  getCurrentOwnerId,
  getCurrentPlayableAsRadio,
  getCurrentPlaylistType,
  getCurrentSeedId,
  getCurrentStationType,
  getCurrentTrackCount,
  getCurrentType,
} from 'state/Playlist/selectors';
import { getCurrentPath } from 'state/Routing/selectors';
import { getInternationalPlaylistRadioEnabled } from 'state/Features/selectors';
import { getProfileId } from 'state/Session/selectors';
import { shufflePlaylist } from 'state/Playlist/actions';
import type { ConnectedProps, DispatchProps } from './Songs';
import type { State } from 'state/types';

const mapDispatchToProps: DispatchProps = { shufflePlaylist };

export default flowRight(
  connect(
    createStructuredSelector<State, ConnectedProps>({
      canAdd: addTrackToPlaylistSelector,
      canEdit: editPlaylistSelector,
      canPlay: playPlaylistOnDemandSelector,
      canSave: saveTrackPlaylistSelector,
      canShuffle: shuffleSelector,
      canShuffleCurated: shuffleCuratedSelector,
      countryCode: getCountryCode,
      internationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
      isCurated: getCurrentIsCurated,
      isDeletable: getCurrentIsDeletable,
      isPremiumPlaylist: getCurrentIsPremiumPlaylist,
      isRenameable: getCurrentIsRenameable,
      isShuffled: getCurrentIsShuffled,
      isWritable: getCurrentIsWritable,
      ownerId: getCurrentOwnerId,
      playableAsRadio: getCurrentPlayableAsRadio,
      playlistType: getCurrentPlaylistType,
      profileId: getProfileId,
      seedId: getCurrentSeedId,
      shareable: getCurrentIsShareable,
      stationType: getCurrentStationType,
      trackCount: getCurrentTrackCount,
      type: getCurrentType,
      url: getCurrentPath,
    }),
    mapDispatchToProps,
  ),
)(Songs);
