import SongsDescription from './SongsDescription';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import {
  getCanPlayCurrentOD,
  getCanPlayCurrentRadio,
  getCurrentAuthor,
  getCurrentDescription,
  getCurrentDuration,
  getCurrentIsCurated,
  getCurrentLastUpdated,
} from 'state/Playlist/selectors';
import { getUserType } from 'state/User/selectors';
import { localize } from 'redux-i18n';
import { subInfoLoadedSelector } from 'state/Entitlements/selectors';
import type { ConnectedProps } from './SongsDescription';
import type { State } from 'state/types';

const mapStateToProps = createStructuredSelector<State, ConnectedProps>({
  author: getCurrentAuthor,
  canPlayCurrentOD: getCanPlayCurrentOD,
  canPlayCurrentRadio: getCanPlayCurrentRadio,
  curated: getCurrentIsCurated,
  description: getCurrentDescription,
  duration: getCurrentDuration,
  lastUpdated: getCurrentLastUpdated,
  subInfoLoaded: subInfoLoadedSelector,
  userType: getUserType,
});

export default flowRight(
  localize('translate'),
  connect(mapStateToProps),
)(SongsDescription);
