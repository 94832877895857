import FollowPlaylistButton from 'components/FollowPlaylistButton';
import HeroBackground from 'components/Hero/HeroBackground';
import HeroContent from 'primitives/Hero/HeroContent';
import HeroMidSection from 'primitives/Hero/HeroMidSection';
import HeroOverlay from 'components/Hero/HeroOverlay/HeroOverlay';
import HeroPlaySection from 'primitives/Hero/HeroPlaySection';
import HeroRoot from 'primitives/Hero/HeroRoot';
import HeroTitle from 'primitives/Hero/HeroTitle';
import PlaylistHeroPlayButton from './PlaylistHeroPlayButton';
import PlaylistImage from 'components/MediaServerImage/PlaylistImage';
import SongsDescription from './SongsDescription';
import theme from 'styles/themes/default';
import ThumbnailWrapper from 'primitives/Hero/ThumbnailWrapper';
import Title from 'components/Hero/HeroTitle/HeroTitle';
import { blur, gravity } from 'utils/mediaServerImageMaker/opsString';
import {
  getAssetImgUrl,
  getDefaultImage,
} from 'views/Playlist/PlaylistProfile/helpers';
import { getCollectionImageUrl } from 'state/Playlist/helpers';
import { PureComponent } from 'react';
import { REQUEST_STATE } from 'state/Playlist/constants';
import { Thumbnail } from 'components/Hero/helpers';

export type ConnectedProps = {
  canPlayPremiumPlaylist: boolean;
  imgUrl: string;
  isPremiumPlaylist: boolean;
  mediaServerUrl: string;
  name?: string;
  playlistId: string;
  playlistUserId: number | string;
  requestState: string;
  seedId: string;
  siteUrl: string;
  stationType: string;
  trackCount: number;
};

export type DispatchProps = {
  resetHero: () => void;
  setHasHero: (hasHero: boolean) => void;
};

export type Props = ConnectedProps & DispatchProps;

export default class PlaylistHero extends PureComponent<Props> {
  showHero = false;

  componentWillUnmount() {
    this.props.resetHero();
    this.props.setHasHero(false);
  }

  getBackgroundSrc() {
    const { trackCount, mediaServerUrl, siteUrl, imgUrl } = this.props;
    const defaultCoverImg = getDefaultImage(mediaServerUrl, siteUrl, {
      blurSigma: 20,
    });
    const defaultImg = getDefaultImage(mediaServerUrl, siteUrl);
    const playlistImageUrl = imgUrl || defaultImg;
    let backgroundSrc;

    if (trackCount <= 0) {
      backgroundSrc = defaultCoverImg;
    } else if (trackCount === 1) {
      backgroundSrc = getCollectionImageUrl(
        { mediaServerUrl, siteUrl },
        // @ts-ignore
        { blurSigma: 20, width: 400 },
        playlistImageUrl,
      );
    } else {
      backgroundSrc = getCollectionImageUrl(
        { mediaServerUrl, siteUrl },
        // @ts-ignore
        { blurSigma: 20, width: 400 },
        playlistImageUrl,
      );
    }

    return backgroundSrc;
  }

  getBackgroundGradient(): string | undefined {
    const { trackCount } = this.props;

    if (trackCount <= 0) {
      return theme.colors.gradients.emptyPlaylist;
    }
    return undefined;
  }

  render() {
    const {
      canPlayPremiumPlaylist,
      imgUrl,
      isPremiumPlaylist,
      mediaServerUrl,
      name,
      playlistId,
      playlistUserId,
      requestState,
      seedId,
      siteUrl,
      stationType,
      trackCount,
    } = this.props;

    const primaryBackground = (
      <PlaylistImage
        background
        ops={[gravity('center'), blur(40)]}
        src={this.getBackgroundSrc()}
      />
    );

    const thumbnailImage = getAssetImgUrl({
      imgUrl,
      mediaServerUrl,
      siteUrl,
      tracksLength: trackCount,
    });

    // The weirdness of Free User My Playlist makes this a necessity to avoid a UI bug
    // The player flow requires that the playlist radio station for My Playlist be re-fetched,
    // and this is more expedient that unraveling the Player and PlayerStateProxy because that
    // will be updated/refactored in an upcoming tech initiative [DEM 03/04/2021]
    if (
      (requestState === REQUEST_STATE.FETCHED ||
        requestState === REQUEST_STATE.PARTIAL) &&
      !this.showHero
    ) {
      this.showHero = true;
    }

    // Only show play button for PREMIUM playlists if the user type is PREMIUM [IHRWEB-17438]
    const showPlayButton =
      (isPremiumPlaylist && canPlayPremiumPlaylist) || !isPremiumPlaylist;

    return (
      <HeroRoot data-test="hero-container">
        <HeroBackground
          backgroundGradient={this.getBackgroundGradient()}
          noMask
          primaryBackground={primaryBackground}
        />
        <HeroOverlay>
          {this.showHero ?
            <HeroMidSection>
              <ThumbnailWrapper css={{ width: '40% !important' }}>
                <Thumbnail
                  alt={name}
                  aspectRatio={1}
                  mediaServerUrl={mediaServerUrl}
                  seedId={seedId}
                  seedType={stationType}
                  siteUrl={siteUrl}
                  src={thumbnailImage}
                />
              </ThumbnailWrapper>
              <HeroContent css={{ width: '60% !important' }}>
                <HeroTitle data-test="hero-image">
                  <Title clamp clampLines={1} text={name} />
                </HeroTitle>
                <SongsDescription totalTracks={trackCount} />
                <HeroPlaySection>
                  {showPlayButton && (
                    <PlaylistHeroPlayButton
                      seedId={seedId}
                      stationType={stationType}
                      trackCount={trackCount}
                    />
                  )}
                  <FollowPlaylistButton
                    playlistId={playlistId}
                    playlistUserId={playlistUserId}
                  />
                </HeroPlaySection>
              </HeroContent>
            </HeroMidSection>
          : null}
        </HeroOverlay>
      </HeroRoot>
    );
  }
}
