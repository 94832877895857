import { Item, Loader as LoaderPrimitive } from './primitives';

function Loader() {
  return (
    <Item>
      <LoaderPrimitive>
        <span />
        <span />
        <span />
      </LoaderPrimitive>
    </Item>
  );
}

export default Loader;
