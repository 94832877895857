import Drag from './Drag';
import styled from '@emotion/styled';

const DragHandleIcon = styled(Drag)({
  paddingBottom: '0.8rem',
  position: 'relative',
  verticalAlign: 'middle',
  width: '2.6rem',
});

export default DragHandleIcon;
