import H3 from 'primitives/Typography/Headings/H3';
import styled from '@emotion/styled';

const Title = styled(H3)(({ theme }) => ({
  fontSize: theme.fonts.size['18'],
  lineHeight: theme.fonts.lineHeight['24'],
  margin: '0 0 8px 0',
}));

export default Title;
