import colors from 'styles/colors';
import { Rules } from 'styles/types';

function iconColor(color: string = colors.white.primary!): Rules {
  return {
    path: {
      fill: color,
    },
    use: {
      fill: color, // we select use here because while chrome adds literal tags as part of their <use/> api, fire fox does not and so we cannot select paths that are added to the dom using <use/>
    },
  };
}

export default iconColor;
