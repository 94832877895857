import BulletSeparator from './BulletSeparator';
import { ReactNode } from 'react';

export default function Line({ children }: { children: ReactNode }) {
  const childArr = Array.isArray(children) ? children : [children];

  if (!children || !childArr.length) {
    return null;
  }

  return (
    <div>
      {childArr
        .filter(child => child)
        .reduce((memo: Array<ReactNode>, el, index, init) => {
          const output = [...memo, el];
          if (index !== init.length - 1) {
            output.push(<BulletSeparator key={`dot-${String(el)}`} />);
          }
          return output;
        }, [])}
    </div>
  );
}
