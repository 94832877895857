import SongsList from 'views/Playlist/PlaylistProfile/SongsList';
import { cloneDeep } from 'lodash-es';
import { Component } from 'react';
import {
  removeTracksFromPlaylist as removeTracksAction,
  reorderTracks as reorderTracksAction,
} from 'state/Playlist/actions';
import type { CurrentPlaylistType } from 'state/Playlist/types';
import type { PlaylistAnalyticsDataParams } from 'modules/Analytics/legacyHelpers';
import type { StationTypeValue } from 'constants/stationTypes';
import type { SyntheticEvent } from 'react';
import type { Track } from 'state/Tracks/types';

type OwnProps = {
  canEdit: boolean;
  isMine: boolean;
  canPlay: boolean;
  overflowEntitlements: {
    showAdd: boolean;
    showSave: boolean;
  };
  playedFrom: string;
  playlistId: string;
  name: string;
  reorderActive: boolean;
  trackIds: Array<{
    trackId: number;
  }>;
  tracks: Array<Track>;
  updateReorderMode: (inReorderMode: boolean) => void;
} & PlaylistAnalyticsDataParams;

export type ConnectedProps = {
  playlistName?: string;
  profileId?: number | null;
  stationType?: StationTypeValue;
  type?: CurrentPlaylistType;
};

export type DispatchProps = {
  removeTracksFromPlaylist: typeof removeTracksAction;
  reorderTracks: typeof reorderTracksAction;
};

export type Props = DispatchProps & ConnectedProps & OwnProps;

type State = {
  deletedTrackIds: Array<string>;
  tempTracks: Array<Track>;
};

class ReorderableSongs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deletedTrackIds: [],
      tempTracks: props.reorderActive ? props.tracks : [],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { reorderActive, tracks } = this.props;
    const tracksChanged = tracks !== nextProps.tracks;
    if (!nextProps.reorderActive && (reorderActive || tracksChanged)) {
      this.setState({
        deletedTrackIds: [],
        tempTracks: [],
      });
    } else if (nextProps.reorderActive && (!reorderActive || tracksChanged)) {
      this.setState({
        deletedTrackIds: [],
        tempTracks: nextProps.tracks,
      });
    }
  }

  onMove = ({ oldIndex, newIndex }: { newIndex: number; oldIndex: number }) => {
    const { tempTracks } = this.state;
    this.setState({
      tempTracks: this.moveTrack(tempTracks, oldIndex, newIndex),
    });
  };

  onDelete = ({ uuid }: { uuid: string }) => {
    const { deletedTrackIds, tempTracks } = this.state;

    this.setState({
      deletedTrackIds: [...deletedTrackIds, uuid],
      tempTracks: this.deleteById(tempTracks, uuid),
    });
  };

  moveTrack = (tracks: Array<Track>, oldIndex: number, newIndex: number) => {
    if (newIndex === oldIndex || newIndex < 0 || oldIndex < 0) return tracks;
    const track = tracks[oldIndex];
    const newTracks = cloneDeep(tracks);
    newTracks.splice(oldIndex, 1); // remove the track at the old index
    newTracks.splice(newIndex, 0, track); // add it back at the new index
    return newTracks;
  };

  deleteById = (tracks: Array<Track>, deleteId: string): Array<Track> =>
    tracks.filter(({ uuid: trackId }) => trackId !== deleteId);

  doneEditing = (e: any) => {
    e.preventDefault();
    const { deletedTrackIds, tempTracks } = this.state;
    const {
      removeTracksFromPlaylist,
      tracks,
      reorderTracks,
      updateReorderMode,
    } = this.props;
    const reordered = tempTracks.some(({ id }, idx) => tracks[idx].id !== id);
    if (deletedTrackIds.length) removeTracksFromPlaylist(deletedTrackIds);
    if (reordered) reorderTracks(tempTracks);
    updateReorderMode(false);
  };

  cancelEditing = (e?: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    this.props.updateReorderMode(false);
  };

  render() {
    const {
      canEdit,
      canPlay,
      isMine,
      overflowEntitlements,
      playedFrom,
      playlistName,
      tracks,
      reorderActive,
    } = this.props;
    const { tempTracks } = this.state;

    return (
      <SongsList
        cancelEditing={this.cancelEditing}
        canEdit={canEdit}
        canPlay={canPlay}
        doneEditing={this.doneEditing}
        isMine={isMine}
        onDelete={this.onDelete}
        onMove={this.onMove}
        overflowEntitlements={overflowEntitlements}
        playedFrom={playedFrom}
        playlistName={playlistName as string}
        reorderActive={reorderActive}
        tracks={reorderActive ? tempTracks : tracks}
      />
    );
  }
}

export default ReorderableSongs;
