import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Form = styled('form')(({ theme }) => ({
  margin: '0 auto',
  textAlign: 'center',
  width: '44rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    maxWidth: 'none',
    width: '50%',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    maxWidth: 'none',
    width: '80%',
  },
}));

export default Form;
