import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const SongDescrptionWrapper = styled.div(({ theme }) => ({
  marginBottom: '1rem',
  paddingRight: '5rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    paddingRight: '3rem',
    fontSize: '1.2rem',
  },
}));

export default SongDescrptionWrapper;
