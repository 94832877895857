// `react-virtualized` imports es modules directly from dist folder to avoid adding unnecessary modules to the bundle
import Loader from './Loader';
import { Children, ReactElement, ReactNode } from 'react';
import { InfiniteLoader } from 'react-virtualized/dist/es/InfiniteLoader';
import { Item, Items, Wrapper } from './primitives';
import { List } from 'react-virtualized/dist/es/List';
import { WindowScroller } from 'react-virtualized/dist/es/WindowScroller';

type Props = {
  batchSize?: number;
  children: ReactNode;
  hasMore: boolean;
  isFetching: boolean;
  loader?: ReactElement<any>;
  loadMore: (params: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<void>;
  offsetBottom?: number;
  overscanRowCount?: number;
  rowHeight: number;
  threshold?: number;
};

function InfiniteScroll({
  batchSize = 10,
  children,
  hasMore,
  isFetching,
  loadMore,
  loader = <Loader />,
  offsetBottom = 0,
  overscanRowCount = 0,
  rowHeight,
  threshold = 10,
}: Props) {
  const items = Children.toArray(children);
  const itemCount = hasMore ? items.length + 1 : items.length;
  const loadMoreItems = isFetching ? () => Promise.resolve() : loadMore;
  const isItemLoaded = (index: number): boolean =>
    !hasMore || index < items.length;

  return (
    <Wrapper offsetBottom={offsetBottom}>
      <Items>
        <InfiniteLoader
          isRowLoaded={index => isItemLoaded(Number(index))}
          loadMoreRows={loadMoreItems}
          minimumBatchSize={batchSize}
          overscanRowCount={overscanRowCount}
          rowCount={itemCount}
          threshold={threshold}
        >
          {({ onRowsRendered, registerChild }) => (
            <WindowScroller>
              {({ height, isScrolling, scrollTop }) => (
                <List
                  autoHeight
                  height={height > 0 ? height : itemCount * rowHeight}
                  isScrolling={isScrolling}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={itemCount}
                  rowHeight={rowHeight}
                  rowRenderer={({ index, key, style }) => (
                    <Item key={key} style={style}>
                      {isItemLoaded(index) ? items[index] : loader}
                    </Item>
                  )}
                  scrollTop={scrollTop}
                  // Hack: with needs to be greater than 0 or else nothing will server-side render.
                  width={1}
                />
              )}
            </WindowScroller>
          )}
        </InfiniteLoader>
      </Items>
    </Wrapper>
  );
}

export default InfiniteScroll;
