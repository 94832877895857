import ElementMeasurer from './ElementMeasurer';
import Measure from 'react-measure';
import { Component } from 'react';

class AutoOverflow extends Component {
  static propTypes = {
    childPairs: PropTypes.arrayOf(
      PropTypes.shape({
        main: PropTypes.element,
        overflow: PropTypes.element,
      }),
    ),
    makeMain: PropTypes.func.isRequired,
    makeOverflow: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      overflow: 0,
      wrapper: Infinity,
    };

    this.onWrapperResize = ev =>
      this.updateComponentWidth('wrapper', ev.entry.width);
    this.onOverflowResize = ev =>
      this.updateComponentWidth('overflow', ev.entry.width);
    this.onElementResize = (index, ev) =>
      this.updateComponentWidth(index, ev.bounds.width);
  }

  getOrderedChildWidths = () =>
    Object.keys(this.state)
      .filter(k => ['wrapper', 'overflow'].indexOf(k) < 0)
      .map(Number)
      .sort((a, b) => a - b)
      .map(k => this.state[k]);

  getVisibleChildCount = () => {
    const wrapperWidth = this.state.wrapper;
    const overflowWidth = this.state.overflow;
    const availableWidth = wrapperWidth - overflowWidth;
    const widths = this.getOrderedChildWidths();
    let childWidthSum = 0;
    let childCount = 0;
    for (let i = 0; i < widths.length; i += 1) {
      const width = widths[i];
      if (!width) return childCount;
      childWidthSum += width + 15;
      if (childWidthSum > availableWidth) {
        if (i === widths.length - 1 && childWidthSum <= wrapperWidth) {
          return childCount + 1;
        }
        return childCount;
      }
      childCount += 1;
    }
    return childCount;
  };

  getMainChildren = (toIndex = Infinity) => {
    const { childPairs } = this.props;
    const mainChildren = childPairs.slice(0, toIndex).map(({ main }) => main);
    return mainChildren;
  };

  getOverflowChildren = (afterIndex = 0) => {
    const { childPairs } = this.props;
    return childPairs.slice(afterIndex).map(({ overflow }) => overflow);
  };

  updateComponentWidth = (index, width) => {
    this.setState({
      [index]: width,
    });
  };

  renderChildMeasurer = () => (
    <div style={{ position: 'fixed', top: -10000, width: '100%' }}>
      {this.getMainChildren().map((child, index) => (
        <ElementMeasurer
          key={child.props.text}
          onResize={ev => this.onElementResize(index, ev)}
        >
          {child}
        </ElementMeasurer>
      ))}
    </div>
  );

  render() {
    const { makeMain, makeOverflow, className } = this.props;
    const childCount = this.getVisibleChildCount();
    const mainChildren = this.getMainChildren(childCount);
    const overflowChildren = this.getOverflowChildren(childCount);
    const contents = [].concat(mainChildren);

    if (overflowChildren.length > 0) {
      contents.push(
        <Measure bounds key="overflow" onResize={this.onOverflowResize}>
          {({ measureRef }) => {
            const overflow = makeOverflow(overflowChildren, measureRef);
            return overflow;
          }}
        </Measure>,
      );
    }

    return (
      <div className={className}>
        {this.renderChildMeasurer()}
        <Measure bounds onResize={this.onWrapperResize}>
          {({ measureRef }) => {
            const wrapper = makeMain(contents, measureRef);
            return wrapper;
          }}
        </Measure>
      </div>
    );
  }
}

export default AutoOverflow;
