import styled from '@emotion/styled';

const ButtonWrapper = styled('div')({
  '& > button': {
    margin: '1rem',
  },
  display: 'flex',
});

export default ButtonWrapper;
