import styled from '@emotion/styled';

type Props = {
  isPlaying: boolean;
};

const Hover = styled('div')<Props>(({ isPlaying, theme }) => ({
  bottom: 0,
  display: 'block',
  left: 0,
  opacity: isPlaying ? 1 : 0,
  position: 'absolute',
  right: 0,
  top: 0,
  transition: 'opacity',
  '&:hover': { opacity: 1 },
  button: {
    fontSize: '1rem',
    height: '50%',
    marginTop: '50%',
    padding: 0,
    transform: 'translateY(-50%)',
    width: '50%',
    zIndex: 1,
    path: {
      fill: theme.colors.white.primary,
    },
  },
}));

export default Hover;
