import styled from '@emotion/styled';

const TrackActions = styled('div')({
  display: 'inline-block',
  padding: '0.1rem 0',
  textAlign: 'right',
  verticalAlign: 'middle',
  width: '3.4rem',
});

export default TrackActions;
