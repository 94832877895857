import Ellipse from 'styles/icons/Ellipse';
import Icon from 'components/Icon/Icon';
import { getUserType } from 'state/User/selectors';
import {
  HeroShuffleIconContainer,
  HeroShuffleRoot,
} from 'primitives/Hero/HeroShuffleIcon';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { useFreeUserPlaylists } from './helpers';
import { useSelector } from 'react-redux';

const ShuffleIcon = () => {
  const {
    isInternationalPlaylistRadio,
    isFreeUserMyPlaylist,
    isFreeUserPlaylistCreation,
    isPlaylistRadio,
  } = useFreeUserPlaylists();
  const userType = useSelector(getUserType);

  return (
      (isFreeUserMyPlaylist ||
        isFreeUserPlaylistCreation ||
        isInternationalPlaylistRadio ||
        isPlaylistRadio) &&
        userType !== SUBSCRIPTION_TYPE.PREMIUM
    ) ?
      <HeroShuffleRoot>
        <Ellipse />
        <HeroShuffleIconContainer>
          <Icon className="shuffle" name="shuffle" />
        </HeroShuffleIconContainer>
      </HeroShuffleRoot>
    : null;
};
export default ShuffleIcon;
