import PlaylistProfileHead, { Props } from './PlaylistProfileHead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentCanonicalUrl,
  getCurrentDescription,
  getCurrentImageUrl,
  getCurrentName,
  getCurrentOwnerId,
  getCurrentPlaylistId,
  getCurrentTrackCount,
  getCurrentTracks,
} from 'state/Playlist/selectors';
import { getMediaServerUrl, getSiteUrl } from 'state/Config/selectors';
import { getSearch } from 'state/Routing/selectors';
import { State } from 'state/types';

export default connect(
  createStructuredSelector<
    State,
    Pick<
      Props,
      | 'pagePath'
      | 'currentTrackCount'
      | 'description'
      | 'image'
      | 'mediaServerUrl'
      | 'name'
      | 'playlistId'
      | 'playlistUserId'
      | 'search'
      | 'siteUrl'
      | 'tracks'
    >
  >({
    pagePath: getCurrentCanonicalUrl,
    currentTrackCount: getCurrentTrackCount,
    description: getCurrentDescription,
    image: getCurrentImageUrl,
    mediaServerUrl: getMediaServerUrl,
    name: getCurrentName,
    playlistId: getCurrentPlaylistId,
    playlistUserId: getCurrentOwnerId,
    search: getSearch,
    siteUrl: getSiteUrl,
    tracks: getCurrentTracks,
  }),
)(PlaylistProfileHead);
