import styled from '@emotion/styled';

const Items = styled('div')({
  height: '100%',
  position: 'relative',
  width: '100%',

  // We unset the width for both children below because we define the width for the items container
  // to be 100%. This is important because it allows us to server-sider render our infinite lists.
  '& > *': {
    width: 'unset !important',
  },

  '.ReactVirtualized__Grid__innerScrollContainer': {
    maxWidth: 'unset !important',
  },
});

export default Items;
