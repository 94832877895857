import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Input = styled('input')(({ theme }) => ({
  border: 'none',
  borderRadius: 0,
  display: 'inline-block',
  fontSize: '2.2rem',
  fontWeight: 'bold',
  height: '3rem',
  lineHeight: 'normal',
  outline: 'none',
  overflow: 'hidden',
  padding: 0,
  position: 'relative',
  verticalAlign: 'bottom',
  whiteSpace: 'nowrap',
  width: '100%',

  '&:hover': {
    border: 'none',
    margin: 0,
    outline: 'none',
    padding: 0,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    fontSize: '2rem',
    height: '2.2rem',
  },
}));

export default Input;
