import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getAssetImgUrl } from '../PlaylistProfile/helpers';
import { getIsMobile } from 'state/Environment/selectors';
import { Helmet } from 'react-helmet';
import { link as playerLink, meta as playerMeta } from 'heads/player';
import { translateKeywords } from 'utils/metaUtils';
import { useSelector } from 'react-redux';
import {
  WIDGET_DIMENSIONS,
  WidgetDimensions,
} from 'constants/widgetDimensions';
import type { Track } from 'state/Tracks/types';

export type Props = {
  pagePath: string;
  currentTrackCount: number;
  description: string;
  facebookAppId?: string;
  facebookPages?: string;
  image: string;
  mediaServerUrl: string;
  name?: string;
  playlistId: string;
  playlistUserId: number | string;
  search: string;
  siteUrl: string;
  tracks: Array<Track | { id: number; title: string }>;
};

function Head({
  pagePath,
  currentTrackCount,
  description,
  mediaServerUrl,
  siteUrl,
  search,
  tracks,
  playlistUserId,
  playlistId,
  name,
  image,
}: Props) {
  const translate = useTranslate();
  const isMobile = useSelector(getIsMobile);
  const isAutoPlay = !isMobile && search.includes('autoplay');
  const deeplinkType = isAutoPlay ? 'play' : 'goto';
  const deeplink = `${deeplinkType}/playlist/${playlistUserId}/${playlistId}`;

  const playlistDescription =
    description ||
    tracks
      .slice(0, 3)
      .map(track => track?.title ?? '')
      .join(', ');

  const keywords = translateKeywords(
    translate,
    'Listen, On Demand, Paid Music, Paid Songs, Songs, Play Songs, iHeartRadio, Playlist, stream, download',
  );

  const title = translate('Listen to the Best Online Music Playlists');

  const imgAssetUrl = getAssetImgUrl({
    imgUrl: image,
    mediaServerUrl,
    meta: true,
    siteUrl,
    tracksLength: currentTrackCount,
  });

  const { height, fixedWidth: width }: WidgetDimensions =
    WIDGET_DIMENSIONS.PLAYLIST ?? {};

  return (
    <>
      <GenericHead
        deeplink={deeplink}
        description={playlistDescription}
        image={imgAssetUrl}
        legacyDeeplink={`play/playlist/${playlistUserId}/${playlistId}`}
        metaDescription={playlistDescription}
        ogType="music.playlist"
        title={name ?? ''}
      />
      <Helmet
        link={playerLink({ pagePath, siteUrl, title })}
        meta={[
          { content: title, itemProp: 'name' },
          { content: `${title}, ${keywords}`, name: 'keywords' },
          ...playerMeta({ height, pagePath, siteUrl, width }),
        ]}
      />
    </>
  );
}

export default Head;
