import BackFillTracksHeader from 'components/BackFillTracksHeader';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlaylistSongsHeader from 'components/PlaylistSongsHeader/PlaylistSongsHeader';
import PlaylistSongsRow from 'views/Playlist/PlaylistProfile/SongRow';
import PlaylistTypes from 'constants/playlistTypes';
import ReorderableSongs from 'views/Playlist/PlaylistProfile/ReorderableSongs';
import ReorderPlaylistContext from 'contexts/ReorderPlaylist';
import { ConnectedModals } from 'state/UI/constants';
import { openModal } from 'state/UI/actions';
import { ReactElement, useMemo } from 'react';
import { shufflePlaylist as shufflePlaylistAction } from 'state/Playlist/actions';
import { STATION_TYPE } from 'constants/stationTypes';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { useDispatch } from 'react-redux';
import type { CurrentPlaylistType } from 'state/Playlist/types';
import type { ShareContext } from 'state/UI/types';
import type { Track } from 'state/Tracks/types';

export type ConnectedProps = {
  canAdd: boolean;
  canEdit: boolean;
  canPlay: boolean;
  canSave: boolean;
  canShuffle: boolean;
  canShuffleCurated: boolean;
  countryCode: string;
  internationalPlaylistRadioEnabled: boolean;
  isCurated: boolean;
  isDeletable: boolean;
  isPremiumPlaylist: boolean;
  isRenameable: boolean;
  isShuffled: boolean;
  isWritable: boolean;
  ownerId: number | null | string;
  playableAsRadio?: boolean;
  playlistType: CurrentPlaylistType;
  profileId: number | null;
  seedId: string;
  shareable: boolean;
  stationType: string;
  trackCount: number;
  type: CurrentPlaylistType;
  url: string;
};

export type DispatchProps = {
  shufflePlaylist: typeof shufflePlaylistAction;
};

type OwnProps = {
  backfillTracks?: Array<Track>;
  isFreePlaylistUser?: boolean;
  isFreePlaylistEnabled?: boolean;
  name: string;
  getShareConfig: () => ShareContext;
  ownerId: string;
  playlistId: string;
  subscriptionType: string;
  tracks: Array<Track>;
};

export type Props = ConnectedProps & DispatchProps & OwnProps;

function Songs({
  backfillTracks = [],
  canEdit,
  canPlay,
  canSave,
  canShuffle,
  canShuffleCurated,
  countryCode,
  internationalPlaylistRadioEnabled,
  isFreePlaylistEnabled,
  isFreePlaylistUser,
  isCurated,
  isDeletable,
  isRenameable,
  isShuffled,
  isWritable,
  name,
  getShareConfig,
  ownerId,
  playableAsRadio = false,
  playlistId,
  profileId,
  seedId,
  shareable,
  shufflePlaylist,
  stationType,
  subscriptionType,
  tracks,
  trackCount,
  type,
}: Props) {
  /**
   * Only display/allow playlist editing if they have the entitlement, they own
   * the playlist
   */
  const dispatch = useDispatch();
  const isMine = String(profileId) === String(ownerId);
  const editable =
    (canEdit && isMine) ||
    ((type === PlaylistTypes.Default || type === PlaylistTypes.User) && isMine);
  const writeable = tracks.length ? editable && isWritable : false;
  const shuffleable = isCurated ? canShuffleCurated : canShuffle;
  const displayBackfills = useMemo(() => {
    return (
      (subscriptionType === SUBSCRIPTION_TYPE.FREE ||
        subscriptionType === SUBSCRIPTION_TYPE.NONE) &&
      ((isFreePlaylistUser && isFreePlaylistEnabled && playableAsRadio) ||
        internationalPlaylistRadioEnabled) &&
      !!trackCount &&
      !isCurated &&
      backfillTracks.length
    );
  }, [
    subscriptionType,
    internationalPlaylistRadioEnabled,
    isCurated,
    isFreePlaylistEnabled,
    isFreePlaylistUser,
    playableAsRadio,
    trackCount,
    backfillTracks,
  ]);

  const backfillRows: Array<ReactElement> = useMemo(() => {
    if (!tracks.length || !displayBackfills) return [];
    const trackIds: Array<number> = tracks.reduce(
      (acc: Array<number>, trk: Track) => {
        acc.push(trk.id);
        return acc;
      },
      [] as Array<number>,
    );

    return backfillTracks
      .filter(bfTrk => !trackIds.includes(bfTrk.id))
      .slice(0, 8)
      .map((track, index) => {
        const {
          artistId,
          artistName,
          id,
          isOnDemandTrack,
          explicitLyrics,
          uuid,
        } = track;

        return (
          <PlaylistSongsRow
            {...track}
            artistId={artistId}
            artistName={artistName}
            canEdit={false}
            canPlay={false}
            disabled
            editMode={false}
            explicitLyrics={explicitLyrics}
            index={index}
            isBackfill
            isMine={isMine}
            isOD={isOnDemandTrack}
            key={uuid}
            onDelete={() => {}}
            overflowEntitlements={{
              showAdd: tracks.every(thisTrack => thisTrack.id !== id),
              showSave: canSave,
            }}
            ownerId={ownerId}
            playedFrom={
              stationType === STATION_TYPE.PLAYLIST_RADIO ?
                String(PLAYED_FROM.PROF_PLAYLIST_RADIO_SONGS)
              : String(PLAYED_FROM.PROF_PLAYLIST_SONGS)
            }
            playlistId={playlistId}
            playlistName={name}
            seedType={STATION_TYPE.PLAYLIST_RADIO}
            showUpsell={false}
            stationType={stationType}
            subKey={`PlaylistRowSortable-${track.id}-${track.trackId}`}
            tracksLength={backfillTracks.length}
          />
        );
      });
  }, [
    backfillTracks,
    canSave,
    isMine,
    name,
    ownerId,
    playlistId,
    stationType,
    tracks,
    displayBackfills,
  ]);

  const onShareClicked = () => {
    dispatch(
      openModal({
        id: ConnectedModals.Share,
        context: getShareConfig(),
      }),
    );
  };

  const playedFrom = (): number => {
    if (type === PlaylistTypes.Default) {
      return PLAYED_FROM.PROF_PLAYLIST_MYPLAYLIST_USERTRACKS;
    } else {
      return stationType === STATION_TYPE.PLAYLIST_RADIO ?
          PLAYED_FROM.PROF_PLAYLIST_RADIO_SONGS
        : PLAYED_FROM.PROF_PLAYLIST_SONGS;
    }
  };

  return (
    <ReorderPlaylistContext.Consumer>
      {({ active, update }) => (
        <>
          {!active ?
            <PlaylistSongsHeader
              canShuffle={shuffleable}
              countryCode={countryCode}
              deletable={isDeletable}
              empty={tracks.length === 0}
              isCurated={isCurated}
              isMine={isMine}
              isShuffled={isShuffled}
              name={name}
              onShare={onShareClicked}
              onShuffle={shufflePlaylist}
              ownerId={ownerId}
              playlistId={playlistId}
              renameable={isRenameable}
              seedId={seedId}
              shareable={shareable}
              type={type}
              writeable={writeable}
            />
          : null}
          <ReorderableSongs
            canEdit={writeable}
            canPlay={canPlay || (isFreePlaylistUser && isFreePlaylistEnabled)}
            curated={isCurated}
            isMine={isMine}
            name={name}
            overflowEntitlements={{
              showAdd:
                !editable || (isFreePlaylistUser && isFreePlaylistEnabled),
              showSave: canSave,
            }}
            playedFrom={playedFrom()}
            playlistId={playlistId}
            reorderActive={active}
            tracks={tracks}
            updateReorderMode={update}
            userId={ownerId}
          />
          {displayBackfills && !active ?
            <>
              <BackFillTracksHeader />
              {backfillRows}
            </>
          : null}
        </>
      )}
    </ReorderPlaylistContext.Consumer>
  );
}

export default Songs;
