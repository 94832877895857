import HeroPlayButton from 'components/Player/HeroPlayButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import PlaylistTypes from 'constants/playlistTypes';
import ShuffleIcon from './ShuffleIcon';
import { ConnectedProps } from './PlaylistHero';
import {
  getCurrentCanPlay,
  getCurrentPlaylistType,
  getCurrentShowPlaylistButtons,
} from 'state/Playlist/selectors';
import { STATION_TYPE } from 'constants/stationTypes';
import { useSelector } from 'react-redux';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

type Props = Pick<ConnectedProps, 'seedId' | 'stationType' | 'trackCount'>;

const PlaylistHeroPlayButton = ({ seedId, stationType, trackCount }: Props) => {
  const canPlay = useSelector(getCurrentCanPlay);
  const playlistType = useSelector(getCurrentPlaylistType);
  const showPlaylistButtons = useSelector(getCurrentShowPlaylistButtons);

  let playedFrom;
  let reportingStationType;
  if (
    playlistType === PlaylistTypes.New4U &&
    stationType === STATION_TYPE.PLAYLIST_RADIO
  ) {
    playedFrom = PLAYED_FROM.PROF_NEW4U_RADIO_PLAY;
  } else if (playlistType === PlaylistTypes.Default) {
    playedFrom = PLAYED_FROM.PROF_PLAYLIST_MYPLAYLIST_HERO;
    reportingStationType = 'my_playlist';
  } else if (stationType === STATION_TYPE.PLAYLIST_RADIO) {
    playedFrom = PLAYED_FROM.PROF_PLAYLIST_RADIO_PLAY;
  } else if (playlistType === PlaylistTypes.New4U) {
    playedFrom = PLAYED_FROM.PROF_NEW4U_PLAY;
  } else {
    playedFrom = PLAYED_FROM.PROF_PLAYLIST_PLAY;
  }

  return seedId && trackCount > 0 && (canPlay || showPlaylistButtons) ?
      <>
        <PlayButton
          ButtonComponent={HeroPlayButton}
          playedFrom={playedFrom}
          reportingStationType={reportingStationType}
          seedId={seedId}
          stationId={seedId}
          stationType={stationType}
        />
        <ShuffleIcon />
      </>
    : null;
};

export default PlaylistHeroPlayButton;
