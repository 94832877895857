import PlaylistHero from './PlaylistHero';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCanPlayPremiumPlaylist,
  getCurrentImageUrl,
  getCurrentIsPremiumPlaylist,
  getCurrentName,
  getCurrentOwnerId,
  getCurrentPlaylistId,
  getCurrentRequestState,
  getCurrentSeedId,
  getCurrentStationType,
  getCurrentTrackCount,
} from 'state/Playlist/selectors';
import { getMediaServerUrl, getSiteUrl } from 'state/Config/selectors';
import { resetHero, setHasHero } from 'state/Hero/actions';
import type { ConnectedProps, DispatchProps } from './PlaylistHero';
import type { State } from 'state/types';

const mapDispatchToProps: DispatchProps = { resetHero, setHasHero };

export default connect(
  createStructuredSelector<State, ConnectedProps>({
    canPlayPremiumPlaylist: getCanPlayPremiumPlaylist,
    imgUrl: getCurrentImageUrl,
    isPremiumPlaylist: getCurrentIsPremiumPlaylist,
    mediaServerUrl: getMediaServerUrl,
    name: getCurrentName,
    playlistId: getCurrentPlaylistId,
    playlistUserId: getCurrentOwnerId,
    requestState: getCurrentRequestState,
    seedId: getCurrentSeedId,
    siteUrl: getSiteUrl,
    stationType: getCurrentStationType,
    trackCount: getCurrentTrackCount,
  }),
  mapDispatchToProps,
)(PlaylistHero);
