import styled from '@emotion/styled';

type Props = {
  offsetBottom?: number;
};

const Wrapper = styled('div')<Props>(({ offsetBottom = 0 }) => ({
  '.ReactVirtualized__List': {
    paddingBottom: `${offsetBottom / 10}rem`,
  },

  height: '100%',
  width: '100%',
}));

export default Wrapper;
