import styled from '@emotion/styled';

const SongLinkContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: '0.3rem',
  paddingTop: '0.5rem',

  a: {
    transform: 'translateY(-0.6rem)',
  },
});

export default SongLinkContainer;
