import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

const RenameButton = styled(NavLink)(({ theme }) => ({
  '&:hover': {
    color: theme.colors.gray.dark,
    textDecoration: 'none',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'none',
  },
}));

export default RenameButton;
