import styled from '@emotion/styled';

type Props = {
  isPlaying: boolean;
};

const SongTitle = styled('span')<Props>(({ isPlaying = false, theme }) => ({
  color: isPlaying ? theme.colors.red['600'] : theme.colors.black.secondary,
  fontWeight: isPlaying ? 'bold' : 'normal',

  a: {
    color: isPlaying ? theme.colors.red['600'] : theme.colors.black.secondary,
  },
}));

export default SongTitle;
