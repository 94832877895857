import Caption from 'primitives/Typography/BodyCopy/Caption';
import Container from './primitives/Container';
import H4 from 'primitives/Typography/Headings/H4';
import useTranslate from 'contexts/TranslateContext/useTranslate';

const BackFillTracksHeader = () => {
  const translate = useTranslate();

  return (
    <Container>
      <H4>{translate('Added By iHeart')}</H4>
      <Caption>
        {translate('Suggestions based on the songs you added.')}
      </Caption>
    </Container>
  );
};

export default BackFillTracksHeader;
