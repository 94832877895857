import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  adjustSpacing: boolean;
};

const ArtistSubtitle = styled('span')<Props>(({ adjustSpacing, theme }) => ({
  ...theme.mixins.ellipsis,
  alignItems: 'center',
  display: 'none',
  fontSize: theme.fonts.size['13'],
  marginTop: adjustSpacing ? '-0.5rem' : '0.12rem',
  padding: '0.5rem 0 0.3rem',
  a: { color: theme.colors.gray['400'] },
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'flex',
    padding: 0,
  },
}));

export default ArtistSubtitle;
