import styled from '@emotion/styled';

const BannerContainer = styled('div')(({ theme }) => ({
  background: theme.colors.gradients.myPlaylistWelcomeBanner,
  borderRadius: '0.6rem',
  color: theme.colors.white.primary,
  marginBottom: '2.4rem',
  padding: '1.6rem',
}));

export default BannerContainer;
