import PlaylistEditName from './PlaylistEditName';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getCurrentName, getCurrentTrackCount } from 'state/Playlist/selectors';
import { getSubscriptionType } from 'state/Entitlements/selectors';
import { localize } from 'redux-i18n';
import { showNotifyGrowl } from 'state/UI/actions';
import { State } from 'state/types';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { updatePlaylistName } from 'state/Playlist/actions';

export default flowRight(
  localize('translate'),
  connect(
    createStructuredSelector<
      State,
      {
        name?: string;
        subscriptionType: keyof typeof SUBSCRIPTION_TYPE;
        trackCount: number;
      }
    >({
      name: getCurrentName,
      subscriptionType: getSubscriptionType,
      trackCount: getCurrentTrackCount,
    }),
    { showNotifyGrowl, updatePlaylistName },
  ),
)(PlaylistEditName);
