import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  gradientStop1?: string;
  gradientStop2?: string;
  height?: string | number;
  width?: string | number;
};

function PlaylistsLarge({
  height = 73,
  gradientStop1 = theme.colors.gray['300'],
  gradientStop2 = theme.colors.gray['400'],
  width = 49,
  ...props
}: Props) {
  const { 'data-test': dataTest, className } = props;

  return (
    <svg
      className={className}
      data-test={dataTest}
      fill="none"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4999 8V42.2C20.7399 40.04 16.0999 39.2 11.1799 40.92C5.81988 42.84 1.69988 47.6 0.739879 53.2C-1.10012 64.16 8.17988 73.52 19.0999 71.8C26.9399 70.56 32.4999 63.36 32.4999 55.4V16H40.4999C44.8999 16 48.4999 12.4 48.4999 8C48.4999 3.6 44.8999 0 40.4999 0H32.4999C28.0999 0 24.4999 3.6 24.4999 8Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear"
          x1="0"
          x2={width}
          y1="0"
          y2={height}
        >
          <stop stopColor={gradientStop1} />
          <stop offset="1" stopColor={gradientStop2} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default PlaylistsLarge;
