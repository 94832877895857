type Props = {
  className?: string;
};

function Drag(props: Props) {
  return (
    <svg
      {...props}
      id="Layer_1"
      version="1.1"
      viewBox="816.4 908 100 100"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            .st0{fill:#232323;}
            .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#231F20;}
            .st2{fill:#231F20;}
            .st3{fill:#111820;}
            .st4{fill:#221F1F;}
            .st5{fill:#010101;}
          `,
        }}
        type="text/css"
      />
      <path
        className="st4"
        d="M912.5,934.7h-92.1c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h92.1c2.2,0,4,1.8,4,4 C916.6,932.9,914.8,934.7,912.5,934.7z"
      />
      <path
        className="st4"
        d="M912.5,971h-92.1c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h92.1c2.2,0,4,1.8,4,4C916.6,969.2,914.8,971,912.5,971z"
      />
      <path
        className="st4"
        d="M912.5,989.1h-92.1c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h92.1c2.2,0,4,1.8,4,4 C916.6,987.3,914.8,989.1,912.5,989.1z"
      />
      <path
        className="st4"
        d="M912.5,952.8h-92.1c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h92.1c2.2,0,4,1.8,4,4 C916.6,951,914.8,952.8,912.5,952.8z"
      />
    </svg>
  );
}

export default Drag;
