import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import saveSongs from 'state/YourLibrary/saveSongs';
import SongRow from './SongRow';
import { AddToPlaylistContext } from 'components/AddToPlaylistModal';
import { connect } from 'react-redux';
import { ConnectedModals } from 'state/UI/constants';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import {
  getAllAccessPreview,
  getIsTrialEligible,
} from 'state/Entitlements/selectors';
import {
  getCanPlayPremiumPlaylist,
  getCurrentIsPremiumPlaylist,
  getCurrentShowPlaylistButtons,
} from 'state/Playlist/selectors';
import {
  getFreeUserMyPlaylistEnabled,
  getInternationalPlaylistRadioEnabled,
} from 'state/Features/selectors';
import { getIsAnonymous } from 'state/Session/selectors';
import { localize } from 'redux-i18n';
import { navigate } from 'state/Routing/actions';
import { openModal, openSignupModal, showNotifyGrowl } from 'state/UI/actions';
import { removeTracksFromPlaylist } from 'state/Playlist/actions';
import { SortableElement } from 'react-sortable-hoc';
import type { State } from 'state/buildInitialState';

type ConnectedProps = {
  allAccessPreview: boolean;
  canPlayPremiumPlaylist: boolean;
  internationalPlaylistRadioEnabled: boolean;
  isAnonymous: boolean;
  isFreeUserMyPlaylistEnabled: boolean;
  isPremiumPlaylist: boolean;
  isTrialEligible: boolean;
  showPlaylistButtons: boolean;
};

export default flowRight(
  SortableElement,
  localize('translate'),
  connect(
    createStructuredSelector<State, ConnectedProps>({
      allAccessPreview: getAllAccessPreview,
      canPlayPremiumPlaylist: getCanPlayPremiumPlaylist,
      internationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
      isAnonymous: getIsAnonymous,
      isFreeUserMyPlaylistEnabled: getFreeUserMyPlaylistEnabled,
      isPremiumPlaylist: getCurrentIsPremiumPlaylist,
      isTrialEligible: getIsTrialEligible,
      showPlaylistButtons: getCurrentShowPlaylistButtons,
    }),
    {
      navigate,
      removeTracksFromPlaylist,
      saveSongs: saveSongs.action,
      openAddToPlaylist: (context: AddToPlaylistContext) =>
        openModal({
          id: ConnectedModals.AddToPlaylist,
          context,
        }),
      openSignup: (context: any) => openSignupModal({ context }),
      showNotifyGrowl,
    },
  ),
  PlayerStateProxy,
)(SongRow);
