import Link from 'primitives/Typography/Links/Link';
import styled from '@emotion/styled';

const ButtonBarWrapper = styled('span')({
  [Link.toString()]: {
    fontSize: '1.3rem',
    margin: '0 0.3rem 0.5rem 1.3rem',
  },
  display: 'inline-block',
});

export default ButtonBarWrapper;
