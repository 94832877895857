import format from 'date-fns/format';
import Line from './Line';
import SongDescrptionWrapper from './primitives/SongDescriptionWrapper';
import Truncate from 'components/Truncate';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { PureComponent } from 'react';

type OwnProps = {
  totalTracks: number;
  translate: IGetTranslateFunctionResponse;
};

export type ConnectedProps = {
  author: string;
  canPlayCurrentOD: boolean;
  canPlayCurrentRadio: boolean;
  curated: boolean;
  description: string;
  duration: number;
  lastUpdated: number;
  subInfoLoaded: boolean;
  userType: string;
};

type Props = OwnProps & ConnectedProps;

export default class PlaylistSongDescription extends PureComponent<Props> {
  getDurationString() {
    const {
      canPlayCurrentOD,
      canPlayCurrentRadio,
      curated,
      duration,
      subInfoLoaded,
      totalTracks,
      userType,
    } = this.props;

    if (userType !== 'PREMIUM') {
      return '';
    }

    if (
      !totalTracks ||
      !duration ||
      (curated && !canPlayCurrentOD && canPlayCurrentRadio) ||
      !subInfoLoaded
    ) {
      return '';
    }

    let durString = '';
    let minRemaining = Math.floor(duration / 60);
    if (minRemaining >= 60) {
      const hours = Math.floor(minRemaining / 60);
      durString += `${hours} hr `;
      minRemaining %= 60;
    }
    if (minRemaining >= 1) {
      durString += `${minRemaining} min`;
    }
    return <span key="duration">{durString}</span>;
  }

  getTrackString() {
    const { translate, totalTracks } = this.props;

    if (!totalTracks) {
      return null;
    }
    return (
      <span key="track">
        {' '}
        {translate(['1 Song', '{n} Songs', 'n'], { n: Number(totalTracks) })}
      </span>
    );
  }

  getAuthorString() {
    const { author, subInfoLoaded, translate } = this.props;

    if (!author || !subInfoLoaded) {
      return null;
    }
    const authorCredit = `${translate('By')} ${author}`;
    return <span key="author">{authorCredit}</span>;
  }

  getLastUpdatedString() {
    const { lastUpdated, totalTracks } = this.props;
    if (!totalTracks) {
      return null;
    }
    return (
      <span key="lastUpdated">{format(new Date(lastUpdated), 'MMM YYYY')}</span>
    );
  }

  renderFirstLine() {
    const { description } = this.props;
    if (description) {
      return (
        <Truncate lines={2} shouldExpandColor="light">
          <span key="description">{description || null} </span>
        </Truncate>
      );
    }
    return (
      <Line>
        {this.getAuthorString()}
        {this.getLastUpdatedString()}
      </Line>
    );
  }

  renderSecondLine() {
    const { curated } = this.props;
    if (curated) {
      return (
        <Line>
          {this.getAuthorString()}
          {this.getDurationString()}
        </Line>
      );
    }
    return (
      <Line>
        {this.getTrackString()}
        {this.getDurationString()}
      </Line>
    );
  }

  render() {
    return (
      <SongDescrptionWrapper>
        {this.renderFirstLine()}
        {this.renderSecondLine()}
      </SongDescrptionWrapper>
    );
  }
}
