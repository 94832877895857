import iconColor from 'styles/helpers/iconColor';
import styled from '@emotion/styled';

type Props = { active: boolean };

const IconWrapper = styled('span')<Props>(({ active, theme }) => ({
  display: 'inline-block',
  height: '2rem',
  padding: '1rem 0rem 2rem',
  width: '2rem',

  svg: {
    ...iconColor(active ? theme.colors.blue.primary : theme.colors.gray.medium),
    display: 'inline-block',
    fontSize: '2rem',
    marginBottom: '-0.1rem',
    verticalAlign: 'middle',
    width: '2rem', // IE needs width to render correctly
  },
}));

export default IconWrapper;
