import * as React from 'react';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';

export const List = SortableContainer(
  ({ children }: { children?: React.ReactNode }) => <div>{children}</div>,
);

export type Props = Pick<
  SortableContainerProps,
  Exclude<keyof SortableContainerProps, 'pressDelay'>
> & { children?: React.ReactNode };

const SortablePlaylist = (props: Props) => (
  <List {...props} pressDelay={20} useDragHandle />
);

export default SortablePlaylist;
