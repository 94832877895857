import styled from '@emotion/styled';

const Duration = styled('div')(({ theme }) => ({
  color: theme.colors.gray.medium,
  display: 'inline-block',
  fontSize: theme.fonts.size['13'],
  letterSpacing: '0.2rem',
  width: '11rem',
}));

export default Duration;
