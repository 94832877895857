import styled from '@emotion/styled';

const SongsHeader = styled.div({
  display: 'flex',
  height: 'auto',
  lineHeight: '2.6rem',
  marginBottom: '15px',
});

export default SongsHeader;
