import * as React from 'react';
import ButtonWrapper from './primitives/ButtonWrapper';
import Container from './primitives/Container';
import FilledButton from 'primitives/Buttons/FilledButton';
import FormContainer from './primitives/FormContainer';
import Input from './primitives/Input';
import InputContainer from './primitives/InputContainer';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import Pencil from 'styles/icons/Pencil';
import PlaylistName from './primitives/PlaylistName';
import PlaylistTypes from 'constants/playlistTypes';
import RenameButton from './primitives/RenameButton';
import ShouldShow from 'components/ShouldShow';
import { Component } from 'react';
import { GrowlIcons } from 'components/Growls/constants';
import { noop } from 'lodash-es';
import { showNotifyGrowl as showNotifyGrowlAction } from 'state/UI/actions';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import type { CurrentPlaylistType } from 'state/Playlist/types';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';

type Props = {
  canEdit: boolean;
  name: string;
  renameModeActive: boolean;
  showNotifyGrowl: typeof showNotifyGrowlAction;
  subscriptionType: keyof typeof SUBSCRIPTION_TYPE;
  trackCount: number;
  translate: IGetTranslateFunctionResponse;
  type: CurrentPlaylistType;
  updatePlaylistName: (name: string) => Promise<void>;
  updateRenameMode: (inRenameMode: boolean) => void;
};

type State = {
  name: string;
};

class PlaylistEditName extends Component<Props, State> {
  state = { name: this.props.name };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { name: currName } = this.state;
    const { name: newName } = nextProps;

    if (currName !== newName) {
      this.setState({ name: newName });
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ name: e.currentTarget.value.slice(0, 140) });

  onClickSave = () => {
    const { showNotifyGrowl, updateRenameMode, updatePlaylistName } =
      this.props;
    updateRenameMode(false);
    updatePlaylistName(this.state.name);
    showNotifyGrowl({
      icon: GrowlIcons.CheckCircle,
      title: 'Updated playlist name',
    });
  };

  onClickCancel = () => {
    const { name, updateRenameMode } = this.props;
    this.setState({ name });
    updateRenameMode(false);
  };

  onClickEdit = () => this.props.updateRenameMode(true);

  render() {
    const {
      canEdit,
      renameModeActive,
      subscriptionType,
      trackCount,
      translate,
      type,
    } = this.props;
    const { name } = this.state;

    const isMyPlaylist = type === PlaylistTypes.Default;
    let playlistHeader: string | null = null;
    if (isMyPlaylist) {
      if (subscriptionType === SUBSCRIPTION_TYPE.FREE) {
        playlistHeader = translate('Added By You');
      }
    }
    return (
      <Container
        data-test="editname-container"
        isEditing={renameModeActive && canEdit}
      >
        {trackCount > 0 && playlistHeader && (
          <PlaylistName>{playlistHeader}</PlaylistName>
        )}
        <ShouldShow shouldShow={renameModeActive && canEdit}>
          <FormContainer method="GET" onSubmit={noop}>
            <InputContainer>
              <Input
                data-test="playlistedit-textbox"
                defaultValue={name}
                onChange={this.handleChange}
              />
            </InputContainer>
            <ButtonWrapper>
              <FilledButton
                data-test="playlistedit-savebtn"
                onClick={this.onClickSave}
                style={{ minWidth: '17rem' }}
                styleType="cta"
                tabIndex={2}
              >
                {translate('Save')}
              </FilledButton>
              <OutlinedButton
                marginRight="2rem"
                onClick={this.onClickCancel}
                tabIndex={1}
              >
                {translate('Cancel')}
              </OutlinedButton>
            </ButtonWrapper>
          </FormContainer>
        </ShouldShow>
        <ShouldShow shouldShow={!renameModeActive && canEdit}>
          <>
            <NavLink classes={['rename-input']} onClick={this.onClickEdit}>
              <PlaylistName data-test="editname-name">{name}</PlaylistName>
            </NavLink>
            <RenameButton onClick={this.onClickEdit}>
              <span data-test="edit-pencil-icon">
                <Pencil css={{ marginLeft: '4rem' }} />
              </span>
            </RenameButton>
          </>
        </ShouldShow>
      </Container>
    );
  }
}

export default PlaylistEditName;
