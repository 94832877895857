import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const PlaylistListHeading = styled('div')(({ theme }) => ({
  borderBottom: 'none',
  borderTop: 'none',
  paddingBottom: '0.6rem',
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'none',
  },
}));

export default PlaylistListHeading;
