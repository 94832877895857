import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  disabled: boolean;
  isBackfill?: boolean;
  isDraggable: boolean;
  isPlaying: boolean;
};

const Track = styled('div')<Props>(({
  disabled,
  isBackfill = false,
  isDraggable,
  isPlaying,
  theme,
}) => {
  let width;
  if (isBackfill) {
    width = '50%';
  } else if (isDraggable) {
    width = 'calc(50% - 10.5rem)';
  } else {
    width = 'calc(50% - 7.5rem)';
  }
  return {
    color: isPlaying ? theme.colors.red.primary : 'auto',
    cursor: disabled ? 'default' : 'pointer',
    display: 'inline-block',
    fontWeight: isPlaying ? 'bold' : 'normal',
    lineHeight: '2.16rem',
    marginTop: isBackfill ? '0.75rem' : 'inherit',
    opacity: disabled ? '0.2 !important' : 1,
    padding: '0.1rem 1.5rem',
    paddingLeft: '0',
    userSelect: disabled ? 'none' : 'auto',
    verticalAlign: 'middle',
    width,
    a: {
      ...theme.mixins.ellipsis,
      display: 'inline-block',
      maxWidth: 'calc(100% - 1rem)',
    },
    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      width: isDraggable ? 'calc(100% - 16.9rem)' : 'calc(100% - 10.9rem)',
    },
  };
});

export default Track;
