import styled from '@emotion/styled';

const RemoveTrack = styled('button')(({ theme }) => ({
  backgroundColor: theme.colors.gray['400'],
  border: 'none',
  borderRadius: '50%',
  color: theme.colors.white.primary,
  cursor: 'pointer',
  fontSize: '1.3rem',
  height: '2.8rem',
  marginRight: '1.5rem',
  padding: 0, // IHRWEB-15767 - AA - set explicitly for iOS
  textAlign: 'center',
  width: '2.8rem',
  '.icon-close': {
    lineHeight: '2.8rem',
  },
}));

export default RemoveTrack;
