import styled from '@emotion/styled';

const Art = styled('div')({
  display: 'inline-block',
  height: 'auto',
  margin: '0.75rem 1.5rem 0 0',
  padding: '0',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',
  width: '6rem',
  a: {
    display: 'block',
    height: '6rem',
    position: 'relative',
    width: '6rem',
    img: {
      width: '6rem',
    },
  },
});

export default Art;
