import Body from './primitives/Body';
import Button from './primitives/Button';
import CloseButton from './primitives/CloseButton';
import CloseIcon from 'styles/icons/CloseIcon';
import FilledButton from './primitives/FilledButton';
import NotificationBannerContainer from './primitives/NotificationBannerContainer';
import Title from './primitives/Title';
import TitleContainer from './primitives/TitleContainer';
import useTheme from 'contexts/Theme/useTheme';

type Props = {
  onClose?: () => void;
  message?: string;
  title?: string;
  showClose?: boolean;
  button?: { filled?: boolean; link?: string; text?: string };
  newWindow?: boolean;
};

const NotificationBanner = ({
  onClose,
  message,
  title,
  showClose,
  button,
  newWindow = true,
}: Props) => {
  const theme = useTheme();

  const ButtonEl = button?.filled ? FilledButton : Button;
  return (
    <NotificationBannerContainer>
      <TitleContainer>
        <Title styleType="light" weight="bold">
          {title}
        </Title>
        {showClose ?
          <CloseButton onClick={onClose}>
            <CloseIcon
              fill={theme.colors.white.primary}
              height="20"
              width="20"
            />
          </CloseButton>
        : null}
      </TitleContainer>
      <Body styleType="light">{message}</Body>
      {button ?
        <a
          href={button.link}
          rel="noreferrer"
          target={newWindow ? '_blank' : '_self'}
        >
          <ButtonEl>{button.text}</ButtonEl>
        </a>
      : null}
    </NotificationBannerContainer>
  );
};

export default NotificationBanner;
