import {
  getCollectionImageUrl,
  getCollectionImageUrlMeta,
  getDefaultCollectionImageUrl,
} from 'state/Playlist/helpers';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { metaSuffix } from 'utils/metaUtils';
import { TILE_RES } from 'components/MediaServerImage';

export function buildSocialDescription({
  description,
  translate,
}: {
  description: string;
  translate: IGetTranslateFunctionResponse;
}) {
  const dynamicSocialDescription = metaSuffix(description);
  const templateString = [
    dynamicSocialDescription?.length ? '{dynamicSocialDescription} ' : '',
    'Listen now with iHeart.',
  ].join('');

  return translate(templateString, { dynamicSocialDescription });
}

export function buildMetaDescription({
  description,
  translate,
}: {
  description: string;
  translate: IGetTranslateFunctionResponse;
}) {
  const dynamicDescription = metaSuffix(description);
  const templateString = [
    dynamicDescription?.length ? '{dynamicDescription} ' : '',
    'Listen on demand and play the songs using no wifi internet with a subscription.',
  ].join('');

  return translate(templateString, { dynamicDescription });
}

export function getDefaultImage(
  mediaServerUrl: string,
  siteUrl: string,
  ops: {
    blurSigma?: number;
  } = {},
) {
  // @ts-ignore
  return getDefaultCollectionImageUrl({ mediaServerUrl, siteUrl }, ops);
}

export function getAssetImgUrl({
  mediaServerUrl,
  siteUrl,
  tracksLength,
  imgUrl,
  meta = false,
}: {
  imgUrl: string;
  mediaServerUrl: string;
  siteUrl: string;
  tracksLength: number;
  meta?: boolean;
}) {
  const defaultImg = getDefaultImage(mediaServerUrl, siteUrl);
  const playlistImageUrl = imgUrl || defaultImg;

  if (tracksLength > 0) {
    return !meta ?
        getCollectionImageUrl(
          { mediaServerUrl, siteUrl },
          { width: TILE_RES },
          playlistImageUrl,
        )
      : getCollectionImageUrlMeta(
          { mediaServerUrl, siteUrl },
          playlistImageUrl,
        );
  }

  return defaultImg;
}
