import Body1 from 'primitives/Typography/BodyCopy/Body1';
import styled from '@emotion/styled';

const Body = styled(Body1)(({ theme }) => ({
  fontSize: theme.fonts.size['16'],
  lineHeight: theme.fonts.lineHeight['21'],
  fontWeight: theme.fonts.weight.regular,
}));

export default Body;
