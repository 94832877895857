import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const scale = keyframes({
  '0%, 80%, 100%': { transform: `scale(0)` },
  '40%': { transform: `scale(1)` },
});

function buildAnimation(delay: number): {
  animation: string;
  animationDelay: string;
  animationDuration: string;
  animationFillMode: string;
  animationIterationCount: string;
  animationTimingFunction: string;
} {
  return {
    animation: scale,
    animationDelay: `-${String(delay)}ms`,
    animationDuration: '1400ms',
    animationFillMode: 'both',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
  };
}

const Loader = styled('div')(({ theme }) => ({
  borderRadius: '100%',
  span: {
    '&:nth-of-type(1)': buildAnimation(3200),
    '&:nth-of-type(2)': buildAnimation(1600),
    '&:nth-of-type(3)': buildAnimation(0),

    backgroundColor: theme.colors.red['600'],
    borderRadius: '100%',
    display: 'inline-block',
    height: '1rem',
    margin: '0 0.5rem',
    width: '1rem',
  },
  textAlign: 'center',
  width: '100%',
}));

export default Loader;
