import analytics, { Events } from 'modules/Analytics';
import countryCodes from 'constants/countryCodes';
import FilledButton from 'primitives/Buttons/FilledButton';
import Follow from 'styles/icons/Follow';
import FollowButtonWrapper from 'primitives/Hero/FollowButtonWrapper';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import useMount from 'hooks/useMount';
import useTrackers from 'contexts/Trackers/hooks/useTrackers';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getFollowAnalyticsData } from 'modules/Analytics/legacyHelpers';
import { GrowlVariants } from 'components/Growls/constants';
import { openSignupModal, showGrowl } from 'state/UI/actions';
import { StationSoftgate } from 'state/Config/types';
import { useDispatch } from 'react-redux';

type Props = {
  curated: boolean;
  countryCode: string;
  followable: boolean;
  isFollowed: boolean;
  isLoggedOut: boolean;
  isInternationalPlaylistRadioEnabled: boolean;
  name: string;
  playlistId: string;
  playlistUserId: number;
  profileId: string;
  queryParams: {
    [key: string]: string | number | boolean;
  };
  stationSoftgate: StationSoftgate;
  stationType: string;
  type: string;
  updateFollowPlaylists: (a: {
    followed: boolean;
    playlistId: string;
    playlistUserId: number;
  }) => void;
  search: { queryId: string };
};

const FollowPlaylistButton = (props: Props) => {
  const {
    countryCode,
    isFollowed,
    isInternationalPlaylistRadioEnabled,
    followable,
    queryParams: { follow: followQueryParam },
  } = props;

  const dispatch = useDispatch();
  const translate = useTranslate();
  const trackers = useTrackers();

  const track = (followed: boolean) => {
    const {
      curated,
      name,
      stationType,
      type,
      playlistId,
      playlistUserId,
      profileId,
      search: { queryId },
    } = props;

    analytics.track(
      Events.FollowUnfollow,
      getFollowAnalyticsData({
        followed,
        name,
        playlist: {
          curated,
          id: playlistId,
          type,
          userId: playlistUserId,
        },
        profileId,
        stationType,
        queryId,
      } as any),
    );

    if (followed) {
      trackers.track(Events.Subscribe, {
        id: playlistId,
        name,
        type: 'playlist',
      });
    }
  };

  const showFollowedGrowl = (followed: boolean) => {
    dispatch(
      showGrowl({
        type: GrowlVariants.PlaylistFollowed,
        data: { isFollowed: followed },
      }),
    );
  };

  const toggleFollow = () => {
    const {
      isLoggedOut,
      name,
      playlistId,
      playlistUserId,
      stationSoftgate,
      updateFollowPlaylists,
    } = props;
    const hasSoftgate = !!stationSoftgate?.playlistradio;

    trackers.track(
      isFollowed ? Events.UnfollowPlaylist : Events.FollowPlaylist,
      {
        playlistId,
        playlist: name,
      },
    );

    if (hasSoftgate && isLoggedOut) {
      dispatch(openSignupModal({ context: 'reg-gate' }));
    } else {
      updateFollowPlaylists({
        followed: !isFollowed,
        playlistId,
        playlistUserId,
      });
      track(!isFollowed);
      showFollowedGrowl(!isFollowed);
    }
  };

  useMount(() => {
    if (
      followQueryParam &&
      followQueryParam !== 'false' &&
      !isFollowed &&
      followable
    ) {
      toggleFollow();
    }
  });

  if (!followable) return null;

  return (
      isInternationalPlaylistRadioEnabled || countryCode === countryCodes.US
    ) ?
      <FollowButtonWrapper>
        {isFollowed ?
          <FilledButton
            data-test="playlist-hero-follow-button"
            onClick={toggleFollow}
            size="small"
            styleType="light"
          >
            {translate('Following')}
          </FilledButton>
        : <OutlinedButton
            data-test="playlist-hero-follow-button"
            onClick={toggleFollow}
            size="small"
            styleType="light"
          >
            <Follow />
            {translate('Follow')}
          </OutlinedButton>
        }
      </FollowButtonWrapper>
    : null;
};

export default FollowPlaylistButton;
