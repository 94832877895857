import styled from '@emotion/styled';

type Props = {
  styleType?: 'dark' | 'light';
  textAlign?: 'left' | 'center';
  weight?: 'regular' | 'medium' | 'bold';
};

const H3 = styled('h3')<Props>(({
  styleType = 'dark',
  textAlign = 'left',
  theme,
  weight = 'regular',
}) => {
  const STYLE_TYPES = {
    dark: {
      color: theme.colors.gray['600'],
    },
    light: {
      color: theme.colors.white.primary,
    },
  };
  return {
    color: STYLE_TYPES[styleType].color,
    fontSize: theme.fonts.size['24'],
    fontWeight: theme.fonts.weight[weight],
    letterSpacing: 0,
    lineHeight: theme.fonts.lineHeight['30'],
    margin: '0.6rem 0',
    textAlign,
    transition: 'all 300ms ease-in-out',
  };
});

export default H3;
