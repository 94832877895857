import styled from '@emotion/styled';

const FilledButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.colors.white.primary,
  border: `solid 1px ${theme.colors.white.primary}`,
  borderRadius: '2.5rem',
  cursor: 'pointer',
  color: theme.colors.blueNew['600'],
  marginTop: '1.6rem',
  padding: '0.5rem 1rem',
  lineHeight: theme.fonts.lineHeight['16'],
}));

export default FilledButton;
