import iconColor from 'styles/helpers/iconColor';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  newHero?: boolean;
};

export const HeroShuffleIconContainer = styled('div')<Props>(({
  newHero = false,
  theme,
}) => {
  const widthSize = newHero ? '599' : '768';
  return {
    left: '0.4rem',
    position: 'absolute',
    top: '0.4rem',

    svg: {
      ...iconColor(theme.colors.blue.primary),
    },

    [mediaQueryBuilder(theme.mediaQueries.max.width[widthSize])]: {
      top: 0,

      svg: {
        fontSize: '1.25rem',
      },
    },
  };
});

export const HeroShuffleRoot = styled('div')<Props>(({
  newHero = false,
  theme,
}) => {
  const widthSize = newHero ? '599' : '768';
  return {
    cursor: 'pointer',
    position: 'absolute',
    margin: '6.5rem 0 0 4.5rem',

    [mediaQueryBuilder(theme.mediaQueries.max.width[widthSize])]: {
      bottom: newHero ? '-3.5rem' : '-3rem',
      right: '0.7rem',
      zIndex: 100,

      // ellipse
      '> svg': {
        height: '2.4rem',
        width: '2.4rem',
      },
    },
  };
});
