import ButtonBarWrapper from 'components/ButtonBar/primitives/ButtonBarWrapper';
import Icon from 'components/Icon/Icon';
import IconWrapper from 'components/ButtonBar/primitives/IconWrapper';
import Link from 'primitives/Typography/Links/Link';
import Text from 'components/ButtonBar/primitives/Text';

function ButtonFormat({ active, dataTest, href, iconName, onClick, text }) {
  return (
    <ButtonBarWrapper>
      <Link dataTest={dataTest} href={href} onClick={onClick} underline={false}>
        <IconWrapper active={active}>
          <Icon className="icon" name={iconName} />
        </IconWrapper>
        <Text data-test={text}>{text}</Text>
      </Link>
    </ButtonBarWrapper>
  );
}

export default ButtonFormat;
