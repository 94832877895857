import * as React from 'react';
import * as UPSELL_FROM from 'modules/Analytics/constants/upsellFrom';
import analytics, { Events } from 'modules/Analytics';
import AutoOverflow from 'components/AutoOverflow/AutoOverflow';
import ButtonFormat from 'components/ButtonBar/ButtonFormat';
import countryCodes from 'constants/countryCodes';
import NavLink from 'components/NavLink';
import PlaylistTypes from 'constants/playlistTypes';
import trackers from 'trackers';
import UPSELL from 'constants/upsellTracking';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { BREAKPOINTS } from 'constants/responsive';
import { ConnectedModals } from 'state/UI/constants';
import { DropdownWrapper, RightChunk } from './primitives';
import {
  getCurrentPlaylist,
  getCurrentPlaylistType,
  getCurrentShowPlaylistButtons,
} from 'state/Playlist/selectors';
import { getFollowAnalyticsData } from 'modules/Analytics/legacyHelpers';
import { getIsAnonymous, getProfileId } from 'state/Session/selectors';
import { getUserType } from 'state/User/selectors';
import { MediaQueriesToProps } from 'components/MediaQueries';
import { Menu } from 'components/Tooltip';
import {
  openLoginModal,
  openModal,
  openRemovePlaylistModal,
  openUpsellModal,
} from 'state/UI/actions';
import { SaveDeleteComponent } from 'modules/Analytics/helpers/saveDelete';
import { STATION_TYPE } from 'constants/stationTypes';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { AddToPlaylistContext } from 'components/AddToPlaylistModal';
import type { Ref } from 'react';

type Props = {
  canShuffle: boolean;
  countryCode: string;
  deletable: boolean;
  empty: boolean;
  id?: string;
  isMine: boolean;
  isRenaming?: boolean;
  name?: string;
  onShare: (x?: unknown) => unknown;
  onShuffle: (x?: Event) => unknown;
  ownerId: number | string;
  renameable: boolean;
  shareable: boolean;
  shuffleActive: boolean;
  subscriptionType?: string;
  updateRenameMode: (active: boolean) => void;
  updateReorderMode: (active: boolean) => void;
  writeable: boolean;
  shouldSaveToPlaylist?: boolean;
};

type Config = {
  active: boolean;
  dataTest: string;
  href: string;
  iconName: string;
  key: string;
  onClick: (x?: unknown) => unknown;
  text: string;
};

const PlaylistButtons: React.FC<Props> = props => {
  const {
    canShuffle,
    deletable,
    empty,
    isRenaming,
    onShare,
    onShuffle,
    renameable,
    shareable,
    shuffleActive,
    subscriptionType,
    updateRenameMode,
    updateReorderMode,
    writeable,
    ownerId,
    countryCode,
  } = props;

  const translate = useTranslate();

  // Selectors
  const isAnonymous = useSelector(getIsAnonymous);
  const playlist = useSelector(getCurrentPlaylist);
  const playlistType = useSelector(getCurrentPlaylistType);
  const profileId = useSelector(getProfileId);
  const userType = useSelector(getUserType);
  const showPlaylistButtons = useSelector(getCurrentShowPlaylistButtons);

  // actions
  const dispatch = useDispatch();
  const openAddToPlaylist = (context: AddToPlaylistContext) =>
    openModal({ id: ConnectedModals.AddToPlaylist, context });

  const updateRenameModeCallback = useCallback(() => {
    updateRenameMode(true);
  }, [updateRenameMode]);

  const updateReorderModeCallback = useCallback(() => {
    updateReorderMode(true);
  }, [updateReorderMode]);

  const dispatchOpenRemovePlaylistModal = useCallback(() => {
    dispatch(
      openRemovePlaylistModal({
        playlist,
        userId: Number(ownerId),
      }),
    );
  }, [dispatch, ownerId, playlist]);

  const onShuffleClick = useCallback(
    (e: Event) => {
      if (canShuffle) onShuffle(e);
      else {
        const isNew4U = playlistType === PlaylistTypes.New4U;
        const unshuffle =
          userType !== SUBSCRIPTION_TYPE.PREMIUM || shuffleActive;

        dispatch(
          openUpsellModal({
            upsellFrom:
              isNew4U ?
                UPSELL.NEW4U_RADIO_SHUFFLE
              : UPSELL.PLAYLIST_RADIO_SHUFFLE,
            headerCopy: translate(
              `${
                unshuffle ?
                  'Want to play this playlist in order?'
                : 'Want to shuffle this playlist?'
              } Try iHeart All Access.`,
            ),
            analyticsUpsellFrom:
              isNew4U ?
                UPSELL_FROM.NEW4U_RADIO_SHUFFLE
              : UPSELL_FROM.PLAYLIST_RADIO_SHUFFLE,
          }),
        );

        trackers.track(Events.UpsellOpen, {
          subscriptionType,
          type: 'SHUFFLE',
        });
      }
    },
    [
      canShuffle,
      dispatch,
      onShuffle,
      playlistType,
      shuffleActive,
      subscriptionType,
      translate,
      userType,
    ],
  );

  const onAddToPlaylistClick = useCallback(() => {
    const { name, tracks } = playlist;
    const trackIds = tracks.map(({ trackId }) => trackId);

    if (isAnonymous) {
      dispatch(openLoginModal({ context: 'add_to_playlist' }));
    } else {
      analytics.track(
        Events.FollowUnfollow,
        getFollowAnalyticsData({
          followed: true,
          name,
          playlist,
          profileId,
        }),
      );

      dispatch(
        openAddToPlaylist({
          component: SaveDeleteComponent.ListSongsOverflow,
          trackIds,
          type: STATION_TYPE.COLLECTION,
        }),
      );
    }
  }, [dispatch, playlist, profileId, isAnonymous]);

  const getConfigs = (showRename: boolean) => {
    const rename =
      renameable && showRename ?
        {
          dataTest: 'rename',
          iconName: 'pencil',
          key: 'rename',
          onClick: updateRenameModeCallback,
          text: translate('Rename'),
        }
      : null;

    const remove =
      deletable ?
        {
          dataTest: 'delete-playlist',
          iconName: 'deleted',
          key: 'remove',
          onClick: dispatchOpenRemovePlaylistModal,
          text: translate('Delete Playlist'),
        }
      : null;

    const edit =
      writeable ?
        {
          dataTest: 'edit-playlist',
          iconName: 'playlist-saved',
          key: 'edit',
          onClick: updateReorderModeCallback,
          text: translate('Edit'),
        }
      : null;

    const addToPlaylist =
      !empty && showPlaylistButtons ?
        {
          dataTest: 'add-to-anotherplaylist',
          iconName: 'song-saved',
          key: 'add-to-playlist',
          onClick: onAddToPlaylistClick,
          text: translate('Add to Another Playlist'),
        }
      : null;

    // once all the flagship clients can agree on sharing for Free User My Playlist
    // and Free User Playlist Creation ... this will probably need to change [DEM 05/20/2021]
    const share =
      shareable && !empty ?
        {
          dataTest: 'share',
          iconName: 'share',
          key: 'share',
          onClick: onShare,
          text: translate('Share'),
        }
      : null;

    const shuffle =
      showPlaylistButtons && !(countryCodes.US !== countryCode && !canShuffle) ?
        {
          // shuffle is effectively active for all non-premium users by default,
          // as all they are able to do is radio playback [DEM 03/05/2021]
          active: userType !== SUBSCRIPTION_TYPE.PREMIUM || shuffleActive,
          dataTest: 'shuffle',
          iconName: 'shuffle',
          key: 'shuffle',
          onClick: onShuffleClick,
          text: translate('Shuffle'),
        }
      : null;

    return [shuffle, edit, addToPlaylist, remove, share, rename].filter(
      x => x !== null,
    ) as Array<Config>;
  };

  const mapConfigs = useCallback(
    ({ key, dataTest: _dataTest, ...config }) => ({
      // eslint-disable-next-line react/jsx-props-no-spreading
      main: <ButtonFormat dataTest={key} key={key} {...config} />,
      overflow: (
        <NavLink
          dataTest={`playlist-buttons-${key}`}
          key={key}
          onClick={() => config.onClick()}
          title={config.text}
        >
          {config.text}
        </NavLink>
      ),
    }),
    [],
  );
  const getButtonPairs = (showRename: boolean) =>
    getConfigs(showRename).map(mapConfigs);

  const makeMain = useCallback(
    (children: JSX.Element, ref: Ref<HTMLDivElement>) => (
      <RightChunk data-test="playlist-song-header" ref={ref}>
        {children}
      </RightChunk>
    ),
    [],
  );

  const mapOverflowItems = useCallback(
    child => <Menu.Item key={child.key}>{child}</Menu.Item>,
    [],
  );
  const makeOverflow = (children: JSX.Element, ref: Ref<HTMLDivElement>) => (
    <DropdownWrapper data-test="overflow-dropdown-wrapper" ref={ref}>
      <Menu>
        <Menu.List>{React.Children.map(children, mapOverflowItems)}</Menu.List>
      </Menu>
    </DropdownWrapper>
  );

  if (isRenaming) return null;

  return (
    <div css={{ width: '50%' }}>
      <MediaQueriesToProps
        mediaQueryMappings={[
          {
            props: { buttons: getButtonPairs(true) },
            width: BREAKPOINTS.LARGE,
          },
        ]}
        noMatchProps={{ buttons: getButtonPairs(false) }}
      >
        {({
          buttons,
        }: {
          buttons: {
            main: JSX.Element;
            overflow: JSX.Element;
          };
        }) => (
          <AutoOverflow
            childPairs={buttons}
            css={{ width: '100%' }}
            makeMain={makeMain}
            makeOverflow={makeOverflow}
          />
        )}
      </MediaQueriesToProps>
    </div>
  );
};

export default PlaylistButtons;
