import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  isDraggable: boolean;
};

const Artist = styled('div')<Props>(({ isDraggable, theme }) => ({
  ...theme.mixins.ellipsis,
  color: theme.colors.gray.medium,
  display: 'inline-block',
  fontSize: '1.3rem',
  lineHeight: '1.8rem',
  padding: '0.1rem 1.5rem',
  verticalAlign: 'middle',
  width: isDraggable ? 'calc(50% - 15rem)' : 'calc(50% - 15rem)',
  a: {
    color: theme.colors.gray.medium,
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'none',
  },
}));

export default Artist;
