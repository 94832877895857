import styled from '@emotion/styled';

const NoSongs = styled('div')(({ theme }) => ({
  margin: '1.5rem 0',
  padding: '4.5rem 0',
  textAlign: 'center',

  '.icon': {
    display: 'block',
    color: theme.colors.gray.medium,
    fontSize: theme.fonts.size.xlarge,
    margin: '1.5rem auto',
  },

  '.btn.browse': {
    maxWidth: '24rem',
  },
}));

export default NoSongs;
