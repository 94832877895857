import ReorderableSongs from './ReorderableSongs';
import withAnalytics from 'modules/Analytics/withAnalytics';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import {
  getCurrentName,
  getCurrentPlaylistType,
  getCurrentStationType,
} from 'state/Playlist/selectors';
import { getPlaylistAnalyticsData } from 'modules/Analytics/legacyHelpers';
import { getProfileId } from 'state/Session/selectors';
import {
  removeTracksFromPlaylist,
  reorderTracks,
} from 'state/Playlist/actions';
import type { ConnectedProps, DispatchProps, Props } from './ReorderableSongs';
import type { State } from 'state/types';

function canGetPlaylistAnalyticsData({
  curated,
  name,
  playlistId,
  profileId,
  stationType,
  type,
  userId,
}: Partial<Props>) {
  return [
    curated,
    name,
    playlistId,
    profileId,
    stationType,
    type,
    userId,
  ].every(value => value !== undefined);
}

const mapDispatchToProps: DispatchProps = {
  removeTracksFromPlaylist,
  reorderTracks,
};

export default flowRight(
  connect(
    createStructuredSelector<State, ConnectedProps>({
      playlistName: getCurrentName,
      profileId: getProfileId,
      stationType: getCurrentStationType,
      type: getCurrentPlaylistType,
    }),
    mapDispatchToProps,
  ),
  withAnalytics(
    ({ reorderActive: edit, ...rest }: Props) =>
      getPlaylistAnalyticsData({ ...rest, edit }),
    {
      // all of the canGetPlaylistAnalyticsData code is here because new4u can't be fetched on the server
      // so the props aren't set in time for componentDidMount, but are fine on the client.
      // once we get new4u sharing this won't be an issue
      trackOnDidUpdate: (prevProps: Props, nextProps: Props) =>
        (canGetPlaylistAnalyticsData(nextProps) &&
          !canGetPlaylistAnalyticsData(prevProps)) ||
        nextProps.reorderActive !== prevProps.reorderActive,
      trackOnWillMount: props => canGetPlaylistAnalyticsData(props),
    },
  ),
)(ReorderableSongs);
