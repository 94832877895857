import PlaylistTypes from 'constants/playlistTypes';
import { CurrentPlaylistType } from 'state/Playlist/types';
import {
  getCanPlayCurrentRadio,
  getCurrentIsCurated,
  getCurrentPlaylistType,
} from 'state/Playlist/selectors';
import {
  getFreeUserPlaylistCreationEnabled,
  getInternationalPlaylistRadioEnabled,
} from 'state/Features/selectors';
import { getUserType } from 'state/User/selectors';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const getIsFreeUserMyPlaylist = ({
  playlistType,
  userType,
}: {
  playlistType: CurrentPlaylistType;
  userType: string;
}) =>
  playlistType === PlaylistTypes.Default &&
  userType !== SUBSCRIPTION_TYPE.PREMIUM;

export const useFreeUserPlaylists = () => {
  const playlistType = useSelector(getCurrentPlaylistType);
  const userType = useSelector(getUserType);
  const freeUserPlaylistCreationEnabled = useSelector(
    getFreeUserPlaylistCreationEnabled,
  );
  const internationalPlaylistRadioEnabled = useSelector(
    getInternationalPlaylistRadioEnabled,
  );
  const playlistRadio = useSelector(getCanPlayCurrentRadio);
  const curated = useSelector(getCurrentIsCurated);

  return useMemo(
    () => ({
      isFreeUserMyPlaylist: getIsFreeUserMyPlaylist({ playlistType, userType }),
      isFreeUserPlaylistCreation:
        playlistType === PlaylistTypes.User &&
        !curated &&
        userType !== SUBSCRIPTION_TYPE.PREMIUM &&
        freeUserPlaylistCreationEnabled,
      isInternationalPlaylistRadio: internationalPlaylistRadioEnabled,
      isPlaylistRadio: playlistRadio,
    }),
    [
      freeUserPlaylistCreationEnabled,
      internationalPlaylistRadioEnabled,
      playlistType,
      userType,
      curated,
      playlistRadio,
    ],
  );
};
