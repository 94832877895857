import ElementMeasurerContent from './primitives/ElementMeasurerContent';
import Measure from 'react-measure';
import { Children, cloneElement, Component, ReactElement } from 'react';

type Props = {
  children: ReactElement<any>;
  onResize: () => void;
};

type State = {
  clonedChild: ReactElement<any>;
  initialRender: boolean;
};

export default class ElementMeasurer extends Component<Props, State> {
  state = {
    clonedChild: cloneElement(Children.only(this.props.children)),
    initialRender: true,
  };

  componentDidMount() {
    this.setState({ initialRender: false });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { children } = this.props;
    if (children !== nextProps.children) {
      this.setState({
        clonedChild: cloneElement(Children.only(nextProps.children)),
        initialRender: true,
      });
    }
  }

  componentDidUpdate() {
    if (!this.state.initialRender) return;
    this.setState({ initialRender: false });
  }

  render() {
    const { initialRender, clonedChild } = this.state;
    const { onResize } = this.props;

    return (
      <Measure bounds key={clonedChild.key!} onResize={onResize}>
        {({ measureRef }) => (
          <ElementMeasurerContent
            initialRender={initialRender}
            ref={node => {
              if (node) measureRef(node);
            }}
            style={{
              display: 'inline-block',
              ...(initialRender ?
                { display: 'inline-block', width: '0px' }
              : {}),
            }}
          >
            {clonedChild}
          </ElementMeasurerContent>
        )}
      </Measure>
    );
  }
}
