import styled from '@emotion/styled';

const PlaylistListContainer = styled('div')(({ theme }) => ({
  background: theme.colors.gray['100'],
  position: 'relative',
  width: '100%',
  zIndex: 2,
}));

export default PlaylistListContainer;
