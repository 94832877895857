import styled from '@emotion/styled';

const disabledStyles = {
  cursor: 'default',
  opacity: '0.2 !important',
  userSelect: 'none',
};

const DisabledWrapper = styled.div<{ disabled: boolean }>(({ disabled }) =>
  disabled ? disabledStyles : {},
);

export default DisabledWrapper;
