import FollowPlaylistButton from './FollowPlaylistButton';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getAmpUrl,
  getCountryCode,
  getStationSoftgate,
} from 'state/Config/selectors';
import {
  getCurrentSeedId,
  getCurrentStationType,
  getCurrentType,
  getIsCurated,
  getIsFollowable,
  getIsFollowed,
  getName,
} from 'state/Playlist/selectors';
import { getInternationalPlaylistRadioEnabled } from 'state/Features/selectors';
import { getIsLoggedOut, getProfileId } from 'state/Session/selectors';
import { getQueryParams } from 'state/Routing/selectors';
import { getSearch } from 'state/SearchNew/selectors';
import { updateFollowPlaylists } from 'state/Playlist/actions';

const mapStateToProps = createStructuredSelector({
  ampUrl: getAmpUrl,
  curated: getIsCurated,
  countryCode: getCountryCode,
  followable: getIsFollowable,
  isFollowed: getIsFollowed,
  isLoggedOut: getIsLoggedOut,
  isInternationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
  name: getName,
  profileId: getProfileId,
  queryParams: getQueryParams,
  seedId: getCurrentSeedId,
  stationSoftgate: getStationSoftgate,
  stationType: getCurrentStationType,
  type: getCurrentType,
  search: getSearch,
});

export default connect(mapStateToProps, { updateFollowPlaylists })(
  FollowPlaylistButton,
);
