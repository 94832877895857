import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  disabled: boolean;
};

const TrackDuration = styled('div')<Props>(({ disabled, theme }) => ({
  color: theme.colors.gray.medium,
  display: 'inline-block',
  fontSize: theme.fonts.size['13'],
  opacity: disabled ? '0.2 !important' : 1,
  overflow: 'hidden',
  padding: '0.1rem 0',
  userSelect: disabled ? 'none' : 'auto',
  verticalAlign: 'middle',
  width: '10rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'none',
  },
}));

export default TrackDuration;
