import styled from '@emotion/styled';

const Button = styled('button')(({ theme }) => ({
  backgroundColor: theme.colors.transparent.primary,
  border: `solid 1px ${theme.colors.white.primary}`,
  borderRadius: '2.5rem',
  cursor: 'pointer',
  color: theme.colors.white.primary,
  marginTop: '1.6rem',
  padding: '0.5rem 1rem',
  lineHeight: theme.fonts.lineHeight['16'],
}));

export default Button;
