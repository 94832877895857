import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const FormContainer = styled('form')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    flexDirection: 'column',
  },
}));

export default FormContainer;
