import styled from '@emotion/styled';

const NoSongsMessage = styled('div')(({ theme }) => ({
  fontSize: theme.fonts.size.medium,
  lineHeight: '2.25rem',
  margin: '3rem auto',

  'div:nth-of-type(2)': {
    color: theme.colors.gray.medium,
    fontSize: theme.fonts.size.small,
    marginTop: '0.7rem',
  },
}));

export default NoSongsMessage;
