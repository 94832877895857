import styled from '@emotion/styled';

const Bullet = styled('span')(({ theme }) => ({
  color: theme.colors.gray['400'],
  fontSize: '2.2rem',
  fontWeight: 'bold',
  lineHeight: '1.9rem',
  padding: '0 0.4rem',
  verticalAlign: 'middle',
}));

export default Bullet;
