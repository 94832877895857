import styled from '@emotion/styled';

const DragHandle = styled('span')({
  cursor: 'move',
  paddingTop: '0.7rem',
  textAlign: 'right',
  verticalAlign: 'middle',
  width: '3.4rem',
});

export default DragHandle;
