import H4 from 'primitives/Typography/Headings/H4';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const PlaylistName = styled(H4)(({ theme }) => ({
  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    fontSize: '2rem',
  },
}));

export default PlaylistName;
