import PlaylistButtons from './PlaylistButtons';
import PlaylistEditName from './PlaylistEditName';
import RenamePlaylistContext from 'contexts/RenamePlaylist';
import ReorderPlaylistContext from 'contexts/ReorderPlaylist';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getIsUserPremium } from 'state/Entitlements/selectors';
import { getProfileId } from 'state/Session/selectors';
import { shufflePlaylist } from 'state/Playlist/actions';
import { SongsHeader } from './primitives';
import { State } from 'state/types';
import type { CurrentPlaylistType } from 'state/Playlist/types';

type Props = {
  canShuffle: boolean;
  countryCode: string;
  deletable: boolean;
  empty: boolean;
  isCurated: boolean;
  isMine: boolean;
  isShuffled: boolean;
  name: string;
  onShare: () => void;
  onShuffle: typeof shufflePlaylist;
  ownerId: string;
  playlistId: string;
  profileId: number | null;
  renameable: boolean;
  seedId: string;
  shareable: boolean;
  type: CurrentPlaylistType;
  writeable: boolean;
};

function PlaylistSongsHeader({
  canShuffle,
  countryCode,
  deletable,
  empty,
  isCurated,
  isMine,
  isShuffled,
  name,
  onShare,
  onShuffle,
  ownerId,
  playlistId,
  profileId,
  renameable,
  seedId,
  shareable,
  type,
  writeable,
}: Props) {
  return (
    <SongsHeader>
      <RenamePlaylistContext.Consumer>
        {({ active, update: updateRenameMode }) => (
          <>
            <PlaylistEditName
              canEdit={renameable}
              renameModeActive={active}
              type={type}
              updateRenameMode={updateRenameMode}
            />
            {!active ?
              <ReorderPlaylistContext.Consumer>
                {({ update: updateReorderMode }) => (
                  <PlaylistButtons
                    canShuffle={canShuffle}
                    countryCode={countryCode}
                    deletable={deletable}
                    empty={empty}
                    isMine={isMine}
                    name={name}
                    onShare={onShare}
                    onShuffle={() => {
                      onShuffle({
                        curated: isCurated,
                        id: playlistId,
                        name,
                        profileId: Number(profileId),
                        seedId,
                        shuffled: isShuffled,
                        type,
                        userId: Number(ownerId),
                      });
                    }}
                    ownerId={ownerId}
                    renameable={renameable}
                    shareable={shareable}
                    shouldSaveToPlaylist={false}
                    shuffleActive={isShuffled}
                    updateRenameMode={updateRenameMode}
                    updateReorderMode={updateReorderMode}
                    writeable={writeable}
                  />
                )}
              </ReorderPlaylistContext.Consumer>
            : null}
          </>
        )}
      </RenamePlaylistContext.Consumer>
    </SongsHeader>
  );
}

const mapStateToProps = createStructuredSelector<
  State,
  { profileId: number | null; isUserPremium: boolean }
>({
  profileId: getProfileId,
  isUserPremium: getIsUserPremium,
});

export default connect(mapStateToProps)(PlaylistSongsHeader);
